import React, { useEffect } from "react";
import aiChipIcon from "../../assets/images/ai-chip.svg";
import flexibleIcon from "../../assets/images/flexible.svg";
import intuitiveIcon from "../../assets/images/intuitive.svg";
import scalableIcon from "../../assets/images/scalable.svg";
import secureIcon from "../../assets/images/secure.svg";

import databaseImg from "../../assets/images/database-icon.svg";
import appImg from "../../assets/images/app-icon.svg";
import qualityImg from "../../assets/images/quality-icon.svg";
import scaleImg from "../../assets/images/scale-icon.svg";
import mailImg from "../../assets/images/mail-svgrepo-com.svg";
import { useNavigate } from "react-router-dom";
import {
  APP,
  CANVASAI_INTRO_VIDEO,
  VIDEO_SERVICE,
} from "../../Constants/api.constant";
import "./Home.css";
import { API_BASE_URL } from "../../Configuration/ServerConfig.js";
import powered from "../../assets/images/white-regular.svg";
import CarouselComponent from "./CarouselComponent.jsx";
import bgVideo from "./../../assets/images/bgVideo.mp4";
import { removeTransparentBG } from "../../utils/Helper.js";
import GenAIFeatures from "./GenAIFeatures.jsx";
import APP_CONST from "../../Constants/app.constant.js";
import PartnerSection from "./PartnerSection.jsx";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Canvas.ai";
    document.querySelector(".content-area-wrap").classList.remove("darkBG");
    document.querySelector(".content-area-wrap").classList.add("landingPageBG");

    const handleScroll = () => {
      const scrollableElement = document
        .querySelector(".authenticateContainer")
        .getBoundingClientRect();
      const blurValue = Math.min(Math.abs(scrollableElement.y) / 10, 100);
      document.querySelector(
        ".authenticateHeader"
      ).style.backdropFilter = `blur(${blurValue}px)`;
    };

    document
      .querySelector(".content-area-wrap")
      .addEventListener("scroll", handleScroll);

    document.querySelector(".content-area-wrap").scrollTo(0, 0);
    return () => {
      removeTransparentBG();
      document
        .querySelector(".content-area-wrap")
        .removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleVideoLoad = () => {
    document.querySelector(".content-area-wrap").classList.add("transparentBG");
  };

  return (
    <div className="authenticateContainer d-flex flex-column align-items-start">
      <video
        class="video-bg"
        muted
        loop
        autoPlay
        onLoadedData={handleVideoLoad}
      >
        <source src={bgVideo} type="video/mp4"></source>
      </video>
      <CarouselComponent />
      <GenAIFeatures />
      <div className="canvasFeaturesDiv w-100 d-flex flex-column align-items-center">
        <div className="canvasAppsDesc">
          <span className="linear-gradient-heading">
            LTIMindtree Canvas.ai is
          </span>
        </div>
        <div className="row canvasFeaturesRow m-0">
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={flexibleIcon} />
                </span>
                <span className="canvasFeatureName">Flexible</span>
              </div>
              <span className="canvasFeatureDesc">
                The canvas.ai consumption layer offers extensive customization
                options to cater to a wide range of business needs and use case
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={aiChipIcon} />
                </span>
                <span className="canvasFeatureName">Responsible AI</span>
              </div>
              <span className="canvasFeatureDesc">
                The security guard rails and proactive governance ensure
                ethical, transparent and compliant AI usage
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={secureIcon} />
                </span>
                <span className="canvasFeatureName">Secure</span>
              </div>
              <span className="canvasFeatureDesc">
                The platform prioritizes data security and fair usage by
                budgeting coupons, rate limiting and reporting.
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={intuitiveIcon} />
                </span>
                <span className="canvasFeatureName">Intuitive</span>
              </div>
              <span className="canvasFeatureDesc">
                An intuituve user interface enables ease-of-use in building and
                using solutions in a low-code, no-code environment
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={scalableIcon} />
                </span>
                <span className="canvasFeatureName">Scalable</span>
              </div>
              <span className="canvasFeatureDesc">
                Battle tested for speed-to-scale, the platorm promises quick
                response time for all queries
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="videoDiv d-flex justify-center">
        <video
          className="videoContainerHome"
          muted
          loop
          playsInline
          controls
          autoPlay
        >
          <source
            src={`${API_BASE_URL}${VIDEO_SERVICE}${CANVASAI_INTRO_VIDEO}`}
            type="video/mp4"
          ></source>
        </video>
      </div>
      <PartnerSection />
      <div className="navAppsDesc d-flex justify-center align-items-center flex-column">
        <span className="navAppsDescSpan">
          For your AI powered navigator applications and copilots in one place,
          curated and neatly categorized.
        </span>
        <div className="d-flex gap-5">
          <div
            className="creator-apps-button"
            onClick={() => {
              navigate(APP);
            }}
          >
            <span>Go To Gallery</span>
          </div>
          <div
            className="creator-apps-button"
            onClick={() => {
              window.open("/studio/home", "_blank");
            }}
          >
            <span>Go to Creator Studio</span>
          </div>
        </div>
      </div>
      <div className="abstractBG">
        <div className="partnerSectionFooter d-flex w-100">
          <div className="d-flex flex-column justify-center align-items-center contactUs">
            <span className="linear-gradient-heading">Contact Us</span>
            <p className="mailSection m-0 d-flex">
              <img src={mailImg} alt="mailImg" />
              <a
                href="mailto:canvas.ai@ltimindtree.com"
                className="p-0 notranslate"
              >
                canvas.ai@ltimindtree.com
              </a>
            </p>
          </div>
        </div>
        <div className="copyRightSection d-flex justify-center w-100">
          © 2024 LTIMindtree Limited | Translation "
          <img src={powered} alt="powered" /> "
        </div>
      </div>
    </div>
  );
};

export default Home;
