import "./GenAIFeatures.scss";
import APP_CONST from "../../Constants/app.constant";
import RightArrow from "./../../assets/images/right-arrow.svg";

const GenAIFeatures = () => {
  return (
    <div className="genaiFeaturesNewDiv w-100">
      <div className="genaiAppsNewMain">
        <div className="genaiAppsDesc">
          <span className="linear-gradient-heading">
            LTIMindtree Canvas.ai solves
          </span>
          <p className="canvas-ai-text-heading">
            for the unique challenges in an Application’s lifecycle
          </p>
        </div>
        <div className="genaiAppsNewMainInnerSection">
          {APP_CONST.CANVASAI_SOLUTIONS &&
            APP_CONST.CANVASAI_SOLUTIONS.map((solution, index) => {
              return (
                <div
                  className={`genaiAppsSolution w-100 d-flex h-100 justify-content-center gap-5`}
                  key={`genaiAppsSolution-${index}`}
                >
                  <div className="genaiAppsSolutionIndex d-flex justify-content-end">
                    <div className="genaiAppsSolutionIndexInner">
                      {(index + 1).toString().padStart(2, "0")}
                    </div>
                  </div>
                  <div className="genaiAppsSolutionInfo">
                    <div className="genaiAppsSolutionInfoTitle">
                      {solution.title}
                    </div>
                    <div className="genaiAppsSolutionInfoDescription">
                      {solution.description}
                    </div>
                  </div>
                  <div className="genaiAppsSolutionApplications">
                    {solution.subtitles.map((data, ind) => {
                      return (
                        <div
                          className={`genaiAppsSolutionApplicationsInner d-flex gap-4`}
                          key={`genaiAppsSolutionApplicationsInner-${ind}`}
                        >
                          <div className="genaiAppsSolutionApplicationsInnerLeft">
                            <img alt="arrow" src={RightArrow} />
                          </div>
                          <div className="genaiAppsSolutionApplicationsInnerRight mb-4">
                            <span className="genaiAppsSolutionApplicationsInnerHeading">
                              {data.heading}
                            </span>
                            <div className="genaiAppsSolutionApplicationsInnerDesc">
                              {data.desc}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default GenAIFeatures;
