import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeStorage } from "../../utils";
import { logout } from "../../keycloak";
import { APP, HOME, LOGIN } from "../../Constants/api.constant";
import { useLocation } from "react-router-dom";
import logoutImg from "../../assets/images/signout-icon.svg";
import emailIMG from "../../assets/images/mail-icon.svg";
import "./style/userAccount.css";
import { localStorageGetItem } from "../../../src/utils/Storage/storageUtils";
import { Button, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";

const UserAccount = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    removeStorage();
    if (window?.IS_SSO_ENABLED === "TRUE") {
      logout();
    } else {
      navigate(LOGIN);
      setAnchorEl(null);
    }
  };
  const userid = localStorageGetItem("emailId");

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const isdarkheaderrequired = (
    location?.pathname === APP || location?.pathname === HOME
  ).toString();

  const changeSignOutText =
    location?.pathname === APP || location?.pathname === HOME;

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        className={
          isdarkheaderrequired
            ? "button-darkheaderrequired"
            : "button-darkheadernotrequired"
        }
      >
        <PersonOutlineOutlined className="person-icon" />
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="custom-menu"
      >
        <ul className="dropdown-menu-head">
          <li className="menu-opt d-flex align-center user-name">
            <div className="img-box">
              <img alt="" src={emailIMG} />
            </div>
            <div className="text-box">{userid}</div>
          </li>
          <li onClick={handleLogout} className="menu-opt d-flex align-center">
            <div className="img-box">
              <img alt="" src={logoutImg} />
            </div>
            <div className="text-box">
              {changeSignOutText ? "Sign out" : t("chat.signOut")}
            </div>
          </li>
        </ul>
      </Menu>
    </>
  );
};
export default UserAccount;
