import React, { useState } from "react";
import PlayButton from "../../assets/images/playButton.svg";
import VideoPopup from "./VideoPopup";
import VideoBG from "../../assets/images/Video-BG.svg";

const PlayVideo = (props) => {
  const { Name, title } = props?.data;
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleVideoModal = () => {
    setShowVideoModal(true);
  };

  return (
    <div className="videoBody d-flex flex-column align-items-start gap-1 w-100 text-start h-100">
      <div className="videoDiv w-100" onClick={() => handleVideoModal()}>
        <video className="playVideo" playsInline poster={VideoBG}></video>
        <img src={PlayButton} alt="playbutton" className="playButton" />
      </div>
      <div className="videoTitle w-100 pt-2">{title}</div>
      {/* <div className="videoDescription w-100 cursor-pointer"></div> */}
      {showVideoModal && (
        <VideoPopup
          showVideoModal={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          handleVideoModal={handleVideoModal}
          name={Name}
          title={title}
        />
      )}
    </div>
  );
};

export default PlayVideo;
