import "./PartnerSection.css";
import awsImg from "../../assets/images/aws.svg";
import azureImg from "../../assets/images/azure.svg";
import nvidiaImg from "../../assets/images/nvidia.svg";
import ibmImg from "../../assets/images/ibm.svg";
import APP_CONST from "../../Constants/app.constant";

const PartnerSection = () => {
  const getImage = (partner) => {
    switch (partner) {
      case APP_CONST.PARTNERS[0]:
        return awsImg;
      case APP_CONST.PARTNERS[1]:
        return azureImg;
      case APP_CONST.PARTNERS[2]:
        return nvidiaImg;
      case APP_CONST.PARTNERS[3]:
        return ibmImg;
      default:
        break;
    }
  };
  return (
    <div className="partnerSection w-100 d-flex flex-column">
      <div className="partnerSectionContent">
        <span className="linear-gradient-heading">
          {APP_CONST.PARTNERS_TITLE}
        </span>
        <div className="partnerSectionContentMain">
          <div className="partnerSectionContentInner">
            <div className="m-0 partnerSectionContentInnerRow d-flex">
              {[...APP_CONST.PARTNERS, ...APP_CONST.PARTNERS].map(
                (partner, index) => {
                  return (
                    <div
                      className="p-0 m-0 d-flex justify-content-center partnerSectionContentInnerDiv"
                      key={`partnerSectionContentInnerDiv-${index}`}
                    >
                      <img
                        src={getImage(partner)}
                        className={`${partner}Img`}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
